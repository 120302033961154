import React from 'react';
import PropTypes from 'prop-types';
import VideoStyles from './styled';
import WistiaPlayer from '../WistiaPlayer';
import YouTubePlayer from '../YouTubePlayer';
import WidenPlayer from '../WidenPlayer';
import noop from '../../../util/noop';

const players = {
  wistia: WistiaPlayer,
  youtube: YouTubePlayer,
  widen: WidenPlayer,
};

const Video = ({
  source,
  content,
  autoplay = true,
  onPlay = noop,
  ...props
}) => {
  const Player = players[source.toLowerCase()];

  return (
    <VideoStyles>
      <Player
        autoplay={autoplay}
        content={content}
        onPlay={onPlay}
        {...props}
      />
    </VideoStyles>
  );
};

Video.propTypes = {
  source: PropTypes.oneOf(['Wistia', 'YouTube', 'Widen']),
  content: PropTypes.string,
};

export default Video;
