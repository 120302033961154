import React from 'react';
import PropTypes from 'prop-types';

const WidenPlayer = ({ content }) => {
  return (
    <div className='widen_embed'>
      <iframe
        title='VMF.com | Widen'
        src={`//embed.widencdn.net/video/claytonhomes/${content}`}
        frameBorder='0'
        webkitallowfullscreen='true'
        mozallowfullscreen='true'
        allowFullScreen
        allowtransparency='true'
        scrolling='no'
      ></iframe>
    </div>
  );
};

WidenPlayer.propTypes = {
  content: PropTypes.string.isRequired,
};

export default WidenPlayer;
