import styled from 'styled-components';

export default styled.section`
  padding: 7.6875rem ${props => props.theme.padding.inner};
  text-align: center;

  //TODO: Remove happy houses
  // .inner {
  //   max-width: ${props => props.theme.maxWidths.containerSmall};
  //   margin: auto;
  //   display: flex;
  //   flex-direction: column;

  //   @media (min-width: ${props => props.theme.breakpoints.desktop}) {
  //     flex-direction: row;
  //     align-items: center;
  //     max-width: ${props => props.theme.maxWidths.fourOhFour};
  //   }
  // }

  // .primary {
  //   flex: 1 0 auto;
  //   max-width: 602px;

  //   svg {
  //     width: 100%;
  //     height: auto;

  //     #house {
  //       animation: houseRock ease-in-out 2s alternate infinite;
  //       transform-origin: 50% 50%;
  //     }

  //     #cloud_1 {
  //       animation: cloudLoop linear 10s forwards infinite;
  //     }

  //     #cloud_2 {
  //       animation: cloudLoop linear 6s forwards infinite;
  //       animation-delay: -4s;
  //     }

  //     #cloud_3 {
  //       animation: cloudLoop linear 8s forwards infinite;
  //       animation-delay: -4s;
  //     }

  //     #background {
  //       animation: backgroundLoop linear 10s alternate infinite;
  //     }
  //   }
  // }

  // TODO: If happy houses is removed we would probably want to rename this class
  .secondary {
    display: inline-block;
    text-align: left;

    // TODO: Remove happy houses
    //  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    //   margin-left: 9.1875rem;
    // }

    h4,
    h1 {
      margin: 0;
      display: ;
      text-align: center;
    }

    a {
      display: inline-block;
      margin-top: 1.5rem;
      font-size: ${props => props.theme.type.paragraph.default};
      line-height: ${props => props.theme.lineHeights.paragraph.default};
      letter-spacing: ${props => props.theme.letterSpacing.paragraph};
    }

    svg {
      bottom: 0.8em;
    }
  }

  ${props => props.theme.animations.houseRock};
  ${props => props.theme.animations.fourOhFour.backgroundLoop};
  ${props => props.theme.animations.fourOhFour.cloudLoop};
`;
