import React from 'react';
import PropTypes from 'prop-types';
import LoadingStyles from './Loading.styled';
//TODO: Remove happy houses
//import SmilingSunIcon from '../Shared/Icons/SmilingSun';
import Spinner from '../Shared/Icons/Spinner';

const Loading = ({ isLoading = false }) => {
  return (
    <LoadingStyles isLoading={isLoading} className='loading'>
      <div className='loader'>
        <Spinner />
        <h2>Loading</h2>
      </div>
    </LoadingStyles>
  );
};

Loading.propTypes = {};

export default Loading;
