import styled from 'styled-components';

const ErrorStyles = styled.section`
  padding: 5rem ${props => props.theme.padding.inner};

  .inner {
    max-width: ${props => props.theme.maxWidths.containerSmall};
    margin: auto;

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      max-width: ${props => props.theme.maxWidths.container};
    }
  }
`;

export default ErrorStyles;
