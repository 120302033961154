import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

function SEO({
  title,
  description = '',
  lang = 'en',
  meta = [],
  structuredData = false,
}) {
  const defaultTitle = 'Vanderbilt Mortgage and Finance, Inc.';
  return (
    <Helmet
      htmlAttributes={{
        lang: lang || defaultLang,
      }}
      title={title || defaultTitle}
      titleTemplate={title && `%s | ${defaultTitle}`}
      meta={[
        {
          property: 'og:title',
          content: title || defaultTitle,
        },
        {
          name: 'description',
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:type',
          content: 'business.business',
        },
        {
          property: 'og:url',
          itemprop: 'url',
          content: 'https://www.vmf.com',
        },
        {
          property: 'business:contact_data:street_address',
          content: '500 Alcoa Trail',
        },
        {
          property: 'business:contact_data:locality',
          content: 'Maryville, Tennessee',
        },
        {
          property: 'business:contact_data:postal_code',
          content: '37804',
        },
        {
          property: 'business:contact_data:country_name',
          content: 'United States of America',
        },
        {
          property: 'business:contact_data:phone_number',
          content: '1-800-970-7250',
        },
        {
          name: 'keywords',
          property: 'og:keywords',
          content: 'calculate payment, home financing, mortgage, finance',
        },
        {
          name: 'google-site-verification',
          content: 'lXg8LIysIZnbyejVZhswm-C9-JSWKOeya_6iEoMaef0',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: description,
        },
      ].concat(meta)}
    >
      {structuredData && (
        <script type='application/ld+json'>{structuredData}</script>
      )}
    </Helmet>
  );
}

SEO.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
};

export default SEO;
