import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from '../Loading/Loading';
import { useAppContext } from '../../contexts/AppContext';

const Layout = ({ children, isLoading = false, className = '' }) => {
  const { location, action } = useHistory();
  const [state, dispatch] = useAppContext();

  // scroll to page top
  useEffect(() => {
    if (action === 'PUSH') {
      setTimeout(() => {
        scrollTo(0, 0);
      }, 100);
    }
  }, [isLoading]);

  // scroll to internal anchor
  useEffect(() => {
    if (!isLoading && location.hash) {
      const element = document.getElementById(location.hash.replace('#', ''));

      dispatch({ type: 'UPDATE_SCROLL_DIR', scrollDirection: 'down' });

      window.setTimeout(() => {
        if (element) element.scrollIntoView();
      }, 100);
    }
  }, [isLoading]);

  return (
    <main
      className={className}
      style={{ position: 'relative', minHeight: '25rem' }}
    >
      <Loading isLoading={isLoading} />

      {!isLoading && children}
    </main>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
};

export default Layout;
