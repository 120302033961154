import styled from 'styled-components';

const LoadingStyles = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9995;
  height: 100%;
  width: 100vw;
  max-width: 100%;
  box-sizing: border-box;
  background: ${props => props.theme.colors.white};
  animation: ${props =>
    props.isLoading
      ? 'loadingEnter ease-in-out 0s forwards'
      : 'loadingExit ease-in-out 0.4s forwards'};

  .loader {
    position: absolute;
    left: 50%;
    top: 5rem;
    transform: translateX(-50%);
    text-align: center;
    opacity: ${props => (props.isLoading ? 1 : 0)};
    transition: all .4s ease-in-out;
    transition-delay: 1s;
  }

  .icon {
    &-smiling_sun {
      &--rays {
        animation: sunRaysLoop linear 15s forwards infinite;
        transform-origin: 50% 50%;
      }
    }
  }

  ${props => props.theme.animations.sunRaysLoop}
  ${props => props.theme.animations.loadingEnter}
  ${props => props.theme.animations.loadingExit}
`;

export default LoadingStyles;
