import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import noop from '../../../util/noop';

const maybeLoadWistia = () => {
  let el = document && document.getElementById('wistia-js');

  if (document && !el) {
    el = document.createElement('script');
    el.id = 'wistia-js';
    el.async = true;
    el.defer = true;
    el.src = '//fast.wistia.com/assets/external/E-v1.js';

    document.body.appendChild(el);
  }
};

const addWistiaVideo = (id, onReady, autoplay) => () => {
  window._wq = window._wq || [];
  _wq.push({
    id: id,
    onReady: video => {
      video.container.classList.add('loaded');

      if (autoplay) {
        video.play();
      }

      onReady(video);
    },
  });
};

const WistiaPlayer = ({ content, onReady = noop, autoplay = true }) => {
  useEffect(maybeLoadWistia, [content]);
  useEffect(addWistiaVideo(content.trim(), onReady, autoplay), [content]);

  return <div className={`wistia_embed wistia_async_${content.trim()}`} />;
};

WistiaPlayer.propTypes = {
  content: PropTypes.string.isRequired,
  onReady: PropTypes.func,
};

export default WistiaPlayer;
