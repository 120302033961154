import React from 'react';
import PropTypes from 'prop-types';
import ErrorStyles from './Error.styled';
import FourOhFour from '../FourOhFour/FourOhFour';

const Error = ({
  error = "Uh oh, looks like we've encountered an error. Please try again later.",
  status = false,
  link = false,
}) => <FourOhFour status={status} error={error} link={link} />;

Error.propTypes = {
  error: PropTypes.string.isRequired,
};

export default Error;
