import React from 'react';
import FourOhFourStyles from './FourOhFour.styled';
//TODO: Remove with happy houses
//import FourOhFourIcon from '../Shared/Icons/FourOhFour';
import Link from '../Shared/Link';

const defaultLink = {
  to: '/',
  title: 'Back to homepage',
};

const FourOhFour = ({
  status = 404,
  error = 'Page not found.',
  link = defaultLink,
}) => (
  <FourOhFourStyles className='block-four_oh_four'>
    <div className='inner'>
      {/* //TODO: Remove happy houses and rework this section
      <div className="primary">
        <FourOhFourIcon />
      </div> 
      */}
      <div className='secondary'>
        {status && <h4>{status}</h4>}
        <h1>{error}</h1>
        {link && link.to && link.title && (
          <Link to={link.to}>{link.title}</Link>
        )}
      </div>
    </div>
  </FourOhFourStyles>
);

export default FourOhFour;
