import styled from 'styled-components';

export default styled.figure`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  display: block;
  margin: 0;

  .youtube_embed,
  .wistia_embed,
  .widen_embed {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    transition: all 0.4s ease-in-out;

    &.loaded {
      opacity: 1;
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }
`;
