import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const maybeLoadYT = () => {
  let el = document && document.getElementById('youtube-js');

  if (document && !el) {
    el = document.createElement('script');
    el.id = 'youtube-js';
    el.async = true;
    el.defer = true;
    el.src = 'https://www.youtube.com/iframe_api';

    document.body.appendChild(el);
    window.onYouTubeIframeAPIReady = () => {
      const { _yt = [] } = window;

      _yt.forEach(props => new YT.Player(`youtube-${props.videoId}`, props));
    };
  }
};

const addYouTubeVideo = (id, _events, autoplay) => () => {
  const { YT = false } = window;
  const events = {
    ...events,
    onReady: props => {
      props.target.f.classList.add('loaded');

      if (autoplay) {
        props.target.playVideo();
      }

      if (_events.onReady) {
        _events.onReady(props);
      }
    },
  };

  if (YT) {
    new YT.Player(`youtube-${id}`, {
      videoId: id,
      events,
    });
  } else {
    window._yt = window._yt || [];
    window._yt.push({
      videoId: id,
      events,
    });
  }
};

const YouTubePlayer = ({ content, events = {}, autoplay = true }) => {
  useEffect(maybeLoadYT, [content]);
  useEffect(addYouTubeVideo(content.trim(), events, autoplay), [content]);

  return <div className='youtube_embed' id={`youtube-${content.trim()}`} />;
};

YouTubePlayer.propTypes = {
  content: PropTypes.string.isRequired,
  onReady: PropTypes.func,
};

export default YouTubePlayer;
